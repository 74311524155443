import { HomeIcon } from '@heroicons/react/outline';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import Menu from './common/Menu';

const navigation = [
  { name: 'home', href: '/dashboard', icon: HomeIcon },
  { spacer: true },
  { name: 'tenants', href: '/administration/tenants', icon: TravelExploreOutlinedIcon }
];

export const AdminMenu = () => {
  return Menu(navigation);
};

export default AdminMenu;
