import TenantTile from 'components/common/TenantTile';
import Title from 'components/Title';
import { ITenant } from 'interfaces';

function Dashboard() {
  const tenants: ITenant[] = [
    {
      code: 'Mestre',
      description: 'Laboratori di Mestre e Venezia + altri',
      backend_url: 'https://genesys-uat.aidia.it/backend/api/v1/',
      frontend_url: 'https://genesys-uat.aidia.it/',
      slug: 'cane-di-dio'
    }
  ];
  return (
    <div>
      <Title title="Dashboard" />
      <div className="flex">
        {tenants.map((tenant, i) => (
          <div key={i}>
            <TenantTile tenant={tenant} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
