import { tenantService } from 'services/api';
import { ITenant } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import TenantForm from '../TenantForm';
import { useEffect, useState } from 'react';
import toast from 'features/toast';

export const EditTenant = () => {
  const translationPrefix = 'pages.tenant.edit';
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { tenantSlug } = useParams();

  const [tenant, setTenant] = useState<ITenant>();

  useEffect(() => {
    tenantService.get(tenantSlug).then(setTenant);
  }, []);

  const update = async (values) => {
    dispatch(loadingActions.startLoading());
    tenantService.update(tenant.slug, values).then(() => {
      toast.success(t('toasts.entity-updated', { entity: 'Tenant' }));
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <TenantForm tenant={tenant} onSubmit={update} />
    </TenantPage>
  );
};

export default EditTenant;
