import { Route, Routes } from 'react-router-dom';
import Login from './components/pages/public/Login';
import NotFound from './components/pages/NotFound';
import Public from './layouts/Public';
import UnderDevelop from 'components/pages/public/UnderDevelop';
import Tenant from 'layouts/Admin';
import Dashboard from 'components/pages/Dashboard';
import { CreateTenant, EditTenant, Tenants } from 'pages/Tenants';

const AppRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Public />}>
        <Route path="" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="under-develop" element={<UnderDevelop />} />
      </Route>
      <Route path="administration" element={<Tenant />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="tenants">
          <Route path="" element={<Tenants />} />
          <Route path="new" element={<CreateTenant />} />
          <Route path=":tenantSlug" element={<EditTenant />} />
        </Route>
      </Route>
      <Route path="dashboard" element={<Tenant />}>
        <Route path="" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
