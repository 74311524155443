import { Field } from 'react-final-form';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import { ITenant } from 'interfaces';

interface TenantFormParams {
  tenant: ITenant;
  onSubmit: any;
}

export const TenantForm = (params: TenantFormParams) => {
  const translationPrefix = 'entities.tenant';
  const { t } = useTranslation();

  const { onSubmit, tenant } = params;

  return (
    <div>
      {tenant && (
        <ReactFinalForm onSubmit={onSubmit}>
          <div className="flex flex-wrap">
            <div className="w-full mb-4">
              <Field
                initialValue={tenant.code}
                name="code"
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.code`)}
                type="text"
              />
            </div>
            <div className="w-full mb-4">
              <Field
                initialValue={tenant.description}
                name="description"
                component={TextFieldAdapter}
                label={t(`${translationPrefix}.description`)}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
};

export default TenantForm;
