import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAuthState } from '../interfaces';
import { axiosPrivate, axiosPublic } from '../services/axios';

const modulePrefix = 'auth';

const initialState: IAuthState = {
  user: null,
  email: null
};

export const login = createAsyncThunk(
  `${modulePrefix}/login`,
  async (credentials: { username: string; password: string }) => {
    const res = await axiosPublic.post('auth/token/', { ...credentials });
    return { ...res.data, username: credentials.username };
  }
);

export const logout = createAsyncThunk(`${modulePrefix}/logout`, async () => {
  const res = await axiosPrivate.post('auth/logout/');
  return res.data;
});

export const authSlice = createSlice({
  name: modulePrefix,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        const { user, username } = payload;
        state.user = username;
        state.email = user.email;
      })
      .addCase(login.rejected, (state) => {
        state.user = null;
        state.email = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.email = null;
      })
      .addCase(logout.rejected, (state) => {
        state.user = null;
        state.email = null;
      });
  }
});
