import { CircularProgress, circularProgressClasses } from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ReportTwoToneIcon from '@mui/icons-material/ReportTwoTone';
import StopCircleTwoToneIcon from '@mui/icons-material/StopCircleTwoTone';

const StatusIcon = (props: { content: string }) => {
  const { content } = props;
  switch (content) {
    case 'PENDING':
      return (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
            animationDuration: '550ms',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            }
          }}
          size={20}
          thickness={4}
          {...props}
        />
      );
    case 'UP':
      return <CheckCircleTwoToneIcon color="success" />;
    case 'STOPPED':
      return <StopCircleTwoToneIcon />;
    case 'ERROR':
      return <ReportTwoToneIcon className="text-orange-500" />;
    default:
      return null;
  }
};
export default StatusIcon;
