import './App.css';
import AppRoutes from './AppRoutes';
import Spinner from './components/common/Spinner';

function App() {
  return (
    <div className="App">
      <Spinner />
      <AppRoutes />
    </div>
  );
}

export default App;
