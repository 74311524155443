import Breadcrumbs from './Breadcrumbs';

interface TitleProps {
  title: string;
  subtitle?: string;
  breadcrumbs?: { label: string; path: string }[];
}

const Title = (props: TitleProps) => {
  const { title, subtitle } = props;

  return (
    /* This example requires Tailwind CSS v2.0+ */

    <div>
      <Breadcrumbs />

      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {title}
          </h2>
          {subtitle && (
            <h4 className="text-left text-md leading-2 text-gray-700 sm:text-lg">{subtitle}</h4>
          )}
        </div>
      </div>
    </div>
  );
};
export default Title;
