import { tenantService } from 'services/api';
import { ITenant } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import TenantForm from '../TenantForm';
import toast from 'features/toast';

const initTenant: ITenant = {
  code: '',
  description: '',
  slug: null
};

export const CreateTenant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const translationPrefix = 'pages.tenant.new';
  const dispatch = useAppDispatch();

  const addTenant = async (values) => {
    dispatch(loadingActions.startLoading());
    tenantService.add(values).then(() => {
      toast.success(t('toasts.entity-created', { entity: 'Tenant' }));
      navigate('..');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <TenantForm tenant={initTenant} onSubmit={addTenant} />
    </TenantPage>
  );
};

export default CreateTenant;
