import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-pro';
import DataGridCell from 'components/common/table/cell/DataGridCell';
import { ITenant } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import StatusIcon from '../cell/StatusIcon';
import { tenantService } from 'services/api';
import toast from 'features/toast';
import { axiosExternal } from 'services/axios';

export function TenantColumns(refresh: any): GridColumns {
  const { t } = useTranslation();
  const translationPrefix = 'entities.tenant';

  const columns: GridColumns = [
    {
      field: 'code',
      width: 200,
      filterable: false,
      sortable: false,
      headerName: t(`${translationPrefix}.code`),
      renderCell: (params: GridRenderCellParams) => {
        const tenant: ITenant = params.row;
        return (
          <div>
            <Link to={`${tenant.slug}`}>
              <Typography sx={{ fontWeight: 'bold' }} className="text-blue-500">
                {tenant.code}
              </Typography>
            </Link>
          </div>
        );
      }
    }
  ];

  ['description'].forEach((field) => {
    columns.push({
      field: field,
      flex: 1,
      filterable: false,
      sortable: false,
      headerName: t(`${translationPrefix}.${field}`),
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    });
  });

  ['frontend_url'].forEach((field) => {
    columns.push({
      field: field,
      width: 300,
      filterable: false,
      sortable: false,
      headerName: t(`${translationPrefix}.${field}`),
      renderCell: (params: GridRenderCellParams) => {
        const tenant: ITenant = params.row;
        return (
          <a href={`${tenant.frontend_url}`} target="_blank" rel="noopener noreferrer">
            <Typography className="text-blue-500">{tenant.frontend_url}</Typography>
          </a>
        );
      }
    });
  });

  columns.push({
    field: 'status',
    width: 150,
    filterable: false,
    sortable: false,
    headerName: t(`${translationPrefix}.status`),
    renderCell: (params: GridRenderCellParams) => {
      return (
        <DataGridCell
          content={t(`${translationPrefix}.status-options.${params.value}`)}
          Icon={<StatusIcon content={params.value} />}
        />
      );
    }
  });

  columns.push({
    field: 'actions',
    type: 'actions',
    width: 90,
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={1}
        icon={
          <Tooltip title={t('actions.stop')} placement="top">
            <StopIcon />
          </Tooltip>
        }
        onClick={() => {
          tenantService.stopInstance(params.row.slug).then(() => {
            toast.info(`Instance ${params.row.slug} is being stopped.`);
            refresh();
          });
        }}
        label="stop"
      />,
      <GridActionsCellItem
        key={2}
        icon={
          <Tooltip title={t('actions.manage')} placement="top">
            <BuildIcon />
          </Tooltip>
        }
        onClick={() => {
          tenantService.manage(params.row.slug).then((res) => {
            const access_token = res.access_token;
            const refresh_token = res.refresh_token;
            const user = res.user.username;

            const form = document.createElement('form');
            const userInput = document.createElement('input');
            const accessTokenInput = document.createElement('input');
            const refreshTokenInput = document.createElement('input');

            form.setAttribute('method', 'post');
            form.setAttribute(
              'action',
              `${params.row.frontend_url}backend/api/v1/superadmin-login/`
            );

            userInput.value = user;
            userInput.name = 'user';
            userInput.hidden = true;
            form.appendChild(userInput);

            accessTokenInput.value = access_token;
            accessTokenInput.name = 'access_token';
            accessTokenInput.hidden = true;
            form.appendChild(accessTokenInput);

            refreshTokenInput.value = refresh_token;
            refreshTokenInput.name = 'refresh_token';
            refreshTokenInput.hidden = true;
            form.appendChild(refreshTokenInput);

            function submitForm() {
              const newWindow = window.open('', 'superadmin-login', 'width=800,height=600');
              if (window.focus) {
                newWindow.focus();
              }
              newWindow.document.body.appendChild(form);
              newWindow.document.forms[0].submit();
            }
            submitForm();
          });
        }}
        label="manage"
      />,
      <GridActionsCellItem
        key={3}
        icon={
          <Tooltip title={t('actions.delete')} placement="top">
            <DeleteIcon />
          </Tooltip>
        }
        onClick={() => {
          tenantService.delete(params.row.slug).then(() => {
            toast.info(`Instance ${params.row.slug} is being removed.`);
            refresh();
          });
        }}
        label="delete"
      />
    ]
  });

  return columns;
}
