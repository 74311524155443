import React from 'react';
import { Tooltip, Typography } from '@mui/material';

const DataGridCell = (props: { content: string; Icon?: any }) => {
  return (
    <Tooltip
      placement="top"
      arrow
      title={
        <React.Fragment>
          <Typography color="inherit">{props.content}</Typography>
        </React.Fragment>
      }>
      <div className="flex justify-between">
        {props.Icon && <div className="pr-3">{props.Icon}</div>}
        <Typography color="inherit">{props.content}</Typography>
      </div>
    </Tooltip>
  );
};
export default DataGridCell;
