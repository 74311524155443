import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pathnames = pathname.split('/').filter(Boolean);
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      {pathnames.length ? (
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate('/')}
          className="cursor-pointer">
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
      ) : (
        <Typography color="text.primary"> Home </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography color="text.primary" key={name} className="cursor-pointer">
            {name}
          </Typography>
        ) : (
          <Link
            underline="hover"
            color="inherit"
            className="cursor-pointer"
            key={name}
            onClick={() => navigate(routeTo)}>
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
