import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../../app/store';

const Spinner = () => {
  const isLoading: boolean = useAppSelector((state) => {
    return state.loadingSliceData.loading;
  });

  return (
    isLoading && (
      <div className="flex justify-center items-center z-40 backdrop-opacity-10 backdrop-invert absolute top-0 left-0 w-screen h-screen">
        <CircularProgress />
      </div>
    )
  );
};

export default Spinner;
