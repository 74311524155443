import { ITenant } from 'interfaces';

interface TenantTileProps {
  tenant: ITenant;
}
function TenantTile(props: TenantTileProps) {
  const { tenant } = props;
  return <div>TenantTile {tenant.code}</div>;
}

export default TenantTile;
