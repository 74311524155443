import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Public = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return <Outlet />;
};

export default Public;
