import { NavLink } from 'react-router-dom';

/* This example requires Tailwind CSS v2.0+ */
export default function UnderDevelop() {
  return (
    <>
      <div className="flex min-h-screen flex-col bg-white lg:relative">
        <div className="flex flex-grow flex-col w-1/2">
          <main className="flex flex-grow flex-col bg-white">
            <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 pt-10 sm:pt-16">
                <a href="/" className="inline-flex">
                  <span className="sr-only">Aidia SRL</span>
                  <img className="h-12 w-auto" src="https://aidia.it/img/AIDIA.png" alt="" />
                </a>
              </div>
              <div className="my-auto flex-shrink-0 py-16 sm:py-32">
                <p className="text-base font-semibold text-blue-600">404</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Feature under development
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Sorry, we can&apos;t get you the page you&apos;re looking for... just yet. We are
                  doing our best to deliver as soon as possible!
                </p>
                <div className="mt-6">
                  <NavLink to="/">
                    <a href="#" className="text-base font-medium text-blue-600 hover:text-blue-500">
                      Back to home
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </NavLink>
                </div>
              </div>
            </div>
          </main>
          <footer className="flex-shrink-0 bg-gray-50">
            <div className="mx-auto w-full max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
              <nav className="flex space-x-4">
                <a
                  href="mailto:adel.ramadan@aidia.it?cc=info@aidia.it"
                  className="text-sm font-medium text-gray-500 hover:text-gray-600">
                  Contact us
                </a>
              </nav>
            </div>
          </footer>
        </div>
        <div className="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
