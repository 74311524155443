import { AxiosError, AxiosResponse } from 'axios';
import { logout } from 'features';
import toast from 'features/toast';
import { axiosPrivate, axiosPublic } from '.';

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.config?.url === 'auth/logout/') return;
  if (error?.response?.status === 401) {
    try {
      await axiosPublic.post('auth/token/refresh/', {}, { withCredentials: true });
      return axiosPrivate.request(error.config);
    } catch (err) {
      store.dispatch(logout());
    }
  } else {
    toast.error(JSON.stringify(error?.response?.data).slice(0, 255));
  }
  Promise.reject(error);
};
