import { ITenant } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class TenantService extends BaseService<ITenant> {
  constructor() {
    super('tenants');
  }

  stopInstance = async (slug: string) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/${slug}/stop-instance/`);
    return res.data;
  };

  deleteInstance = async (slug: string) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/${slug}/delete-instance/`);
    return res.data;
  };

  manage = async (slug: string) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/${slug}/manage/`);
    return res.data;
  };
}

export const tenantService = new TenantService();
