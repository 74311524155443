import { axiosPrivate } from 'services/axios';

export class BaseService<T extends { slug: string }> {
  modulePrefix = 'base';

  constructor(prefix) {
    this.modulePrefix = prefix;
  }

  getAllPaginated = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  getAllBaseInformation = async (params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}-summary/`, { params });
    return res.data;
  };

  get = async (slug: string) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/${slug}/`);
    return res.data;
  };

  delete = async (slug: string) => {
    const res = await axiosPrivate.delete(`${this.modulePrefix}/${slug}/`);
    return res.data;
  };

  add = async (entity: T) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/`, entity);
    return res.data;
  };

  update = async (slug: string, entity: T) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${slug}/`, entity);
    return res.data;
  };

  options = async () => {
    const res = await axiosPrivate.options(`${this.modulePrefix}/`);
    return res.data;
  };
}
