import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer
} from '@mui/x-data-grid-pro';
import { ButtonGroup } from '@mui/material';

export const EnhancedToolbarProvider = () => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <div className="w-auto flex justify-end">
          <ButtonGroup size="small">
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
          </ButtonGroup>
        </div>
      </GridToolbarContainer>
    );
  };
  return CustomToolbar;
};
