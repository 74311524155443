import { useRef } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { tenantService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedDataGrid } from 'components/common/table/data-grid';
import TenantPage from 'components/common/TenantPage';
import { TenantColumns } from 'components/common/table/common-headers';

export const Tenants = () => {
  const translationPrefix = 'pages.tenant.list';
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const columns: GridColDef[] = TenantColumns(datagridRefresh.current);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <Link to="new">
                <Button variant="outlined" size="small">
                  <AddIcon />
                  {t(`${translationPrefix}.add`)}
                </Button>
              </Link>
            </ButtonGroup>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={tenantService} refresh={datagridRefresh} />
        </div>
      </TenantPage>
    </>
  );
};

export default Tenants;
