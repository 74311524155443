import { classNames } from 'helpers/classNames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const Menu = (navigation: any[]) => {
  const { t } = useTranslation();
  return (
    <nav className="px-2 pt-2 space-y-1">
      {navigation.map((item, index) =>
        item.spacer ? (
          <div className="h-8" key={`spacer-${index}`}></div>
        ) : (
          <NavLink
            key={item.name}
            to={item.href}
            end={item.end}
            className={({ isActive }) =>
              classNames(
                isActive ? 'bg-slate-800 text-white' : 'text-slate-100 hover:bg-slate-800',
                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
              )
            }>
            <div className="flex">
              <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-slate-300" aria-hidden="true" />
              {t(`menu.tenant.${item.name}`)}
            </div>
          </NavLink>
        )
      )}
    </nav>
  );
};

export default Menu;
