import { TextField } from '@mui/material';

export const TextFieldAdapter = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}: any) => (
  <TextField
    {...rest}
    name={name}
    onChange={onChange}
    helperText={meta.touched ? meta.error : ''}
    error={meta.error && meta.touched}
    value={value}
    inputProps={restInput}
    fullWidth
  />
);
